import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import AdditionalServices from '../components/additional-services/additional-services'

const Contact = ({ data }) => (
  <>
    <SEO {...data.wordpressPage.yoast_meta} title="data.wordpressPage.title" />
    <AdditionalServices {...data.wordpressPage.acf.additionalServices} />
  </>
)

export default Contact

export const query = graphql`
  query($wordpressId: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpressId }) {
      ...SeoFragment
      acf {
        ...AdditionalServicesFragment
      }
    }
  }
`
